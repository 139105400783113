:root {
  --main-green: #004d14;
  --dark-green: #005a31;
  --medium-green: #00703d;
  --light-green: #66bb6a;
  --pastel-green: #a5d6a7;
  --white: #f6f7f2;
  --orange: #ff6b18;
  --orange2: #ff8c01;
  --grey: #272727;
  --black: #000;
  --lighter-grey: #4d4d4d;
  --darker: #e1e5dc;
}

#servicesDiv {
  background-color: var(--white);
  width: 100vw;
  height: fit-content;
  display: flex;
  padding-bottom: 2em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
}

#quality {
  width: 90vw;
  align-self: center;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#quality h2 {
  font-size: 2em;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 0;
}

#quality p {
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 300;
  padding: -0.5em;
}

#happy {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: -2em;
}

#happy p {
  padding: 0 1em;
}

#count {
  display: inline-flex;
  align-items: center;
  font-size: 2em;
  font-weight: 400;
  padding: 0 0.25em;
  color: var(--orange);
  width: 4em;
  border-radius: 5px;
  width: fit-content;
  opacity: 0.9;
  margin: 0;
}

#count:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

#count p {
  margin: -1em;
  font-size: 1em;
  font-weight: 400;
}

#count:hover p {
  opacity: 1;
}

#hire {
  height: fit-content;
  width: fit-content;
  min-width: 20em;
  background-color: var(--orange2);
  color: white;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin: 1em;
  margin-top: 0;
}

#hire:hover {
  background-color: var(--orange);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

#hire p {
  margin: 0;
  transition: 0.3s ease-in-out;
  padding: 0.25em 1em;
  text-align: center;
}

#quality a {
  font-size: 1em;
  font-weight: 300;
  color: var(--black);
  text-decoration: none;
}

#solutions {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.service {
  position: relative;
  width: 40em;
  height: 20em;
  border: 1px solid var(--darker);
  border-radius: 5px;
  margin: 0.5em;
  display: flex;
  align-items: center;
  padding: 0.5em;
  transition: 0.3s ease-in-out;
}

.service:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.service img {
  border-radius: 5px;
  height: 100%;
}

.words {
  width: fit-content;
  height: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  text-decoration: solid;
  position: relative;
}

.words h2 {
  margin-left: 1em;
  font-weight: 300;
  font-size: 1.5em;
}

.words p {
  text-align: left;
  margin: 0.5em;
  margin-left: 1em;
  line-height: 1.5;
  font-weight: 300;
  font-size: 1.15em;
}

.learn {
  height: 2.5em;
  width: fit-content;
  background-color: var(--orange);
  color: var(--white);
  border-radius: 3px;
  padding: 0 1em;
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.5em;
  bottom: 1em;
}

.learn:hover {
  background-color: var(--orange2);
}

.learn a {
  color: white;
  text-decoration: none;
}

.learn #link {
  color: white;
  text-decoration: none;
}

.learn p {
  margin: 0.5em 0;
  font-size: 1em;
  font-weight: 400;
}

@media screen and (max-width: 1310px) and (min-width: 700px) {
  #quality {
    width: 100vw;
    justify-content: center;
    margin-top: -2em;
  }

  #quality h2,
  p {
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  #solutions {
    width: 90vw;
  }

  .service {
    flex-direction: column;
    width: 80%;
    height: 32.5em;
    border: 1px solid var(--darker);
  }

  .service img {
    width: 100%;
  }

  #quality {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-top: 1.5em;
  }

  #quality h2 {
    text-align: center;
    font-size: 1.75em;
    margin: 0;
  }

  #quality p {
    font-size: 1.25em;
    margin: 1em 0;
  }

  #count {
    font-size: 1.25em;
    padding: 0;
    margin: 0;
  }

  #count p {
    padding: 0 0.25em;
    margin: 0;
  }

  #happy {
    flex-direction: column;
  }

  .words h2 {
    font-size: 1.25em;
    margin: 0;
  }

  .words p {
    font-size: 1em;
    margin: 0;
  }

  .learn {
    font-size: 1em;
    padding: 0 1em;
    bottom: 0.5em;
  }
}
