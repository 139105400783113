:root {
  --main-green: #004d14;
  --dark-green: #005a31;
  --medium-green: #00703d;
  --light-green: #66bb6a;
  --pastel-green: #a5d6a7;
  --white: #f6f7f2;
  --orange: #ff6b18;
  --orange2: #ff8c01;
  --grey: #272727;
  --black: #000;
  --lighter-grey: #4d4d4d;
  --darker: #e1e5dc;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
}

#horizontal-scroll-container {
  display: block;
  padding: 1em;
  width: 100%;
  overflow-x: hidden;
}

#top {
  align-items: center;
  width: 100vw;
  display: flex;
}

#top h3 {
  font-size: 1.3em;
  font-weight: 300;
  margin-left: 2em;
}

.scroll-container {
  position: relative;
  display: flex;
  margin: 1em 0;
  padding: 1em;
  left: -1em;
  width: fit-content;
  flex: 1;
  will-change: transform;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid var(--pastel-green);
  background-color: var(--white);
}

#scroll-btns {
  right: 1em;
  position: absolute;
}

.scroll-button {
  background: var(--white);
  border: 1px solid var(--pastel-green);
  border-radius: 3px;
  cursor: pointer;
  width: 1.75em;
  height: 1.75em;
  border: none;
  margin: 0.25em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.scroll-button:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: var(--pastel-green);
}

.scroll-button img {
  width: 1.1em;
  height: 1.1em;
}

.offer-item {
  height: 18em;
  width: 18em;
  justify-content: center;
  margin: 0.5em;
  background-color: var(--white);
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  display: inline-flex;
  transition: 0.5s ease-in-out;
}

.offer-item img {
  width: 100%;
  height: auto;
  position: absolute;
}

.details {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--white);
  background-color: var(--dark-green);
  transition: all 0.3s ease-in-out;
  bottom: -80%;
}

.det {
  width: 100%;
  height: 75%;
}

.det-head {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1em;
}

.det-head img {
  width: 1.2em;
  height: 1.2em;
  right: 1em;
}

.det-head h3 {
  font-weight: 300;
  font-size: 1.25em;
}

.det h4 {
  font-weight: 300;
  font-size: 1em;
  margin: 0 1em;
}

.det p {
  font-size: 1em;
  font-weight: 300;
  position: relative;
  margin-left: 1em;
  margin-top: 0.5em;
}

.det a {
  text-decoration: underline;
  color: var(--dark-green);
}

.open-det {
  bottom: -25%;
  opacity: 1;
  background-color: white;
  color: var(--grey);
}

.end-btns {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
  right: 0;
  bottom: 5em;
  width: 12em;
  margin: 1em;
}

.end-btns button {
  padding: 0.3em 0.8em;
  border: none;
  background-color: var(--white);
  color: var(--grey);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
  line-height: 1.5;
  align-content: center;
  font-size: 1em;
  font-weight: 300;
  margin: 0;
  width: fit-content;
  text-wrap: nowrap;
}

.end-btns button:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  background-color: var(--dark-green);
  color: var(--white);
}

.pop-up {
  display: none;
}

.show {
  display: block;
  width: 100%;
  height: fit-content;
  min-height: 5em;
  bottom: 1em;
  background-color: var(--white);
  padding: 1em;
  border-radius: 3px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.close-popup {
  top: 1em;
  position: absolute;
  right: 1em;
  transition: 0.3s ease-in-out;
  background-color: white;
  border-radius: 3px;
  padding: 0.25em;
}

.close-popup img {
  width: 1.2em;
  height: 1.2em;
}

.close-popup:hover {
  transform: scale(1.1);
  background-color: var(--white);
}
