:root {
  --main-green: #004d14;
  --dark-green: #005a31;
  --medium-green: #00703d;
  --light-green: #66bb6a;
  --pastel-green: #a5d6a7;
  --white: #f6f7f2;
  --orange: #ff6b18;
  --orange2: #ff8c01;
  --grey: #272727;
  --black: #000;
  --lighter-grey: #4d4d4d;
  --darker: #e1e5dc;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
}

#roadmap {
  background-color: white;
  width: 100vw;
  height: fit-content;
  padding: 0;
  padding-bottom: 2em;
  justify-items: center;
}

#roadmap h3 {
  font-size: 2em;
  font-weight: 300;
  width: 100vw;
  text-align: center;
  margin: 0.5em;
}

#steps {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.step {
  width: 18em;
  height: 10em;
  margin: 0.25em;
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
}

.step:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  background-color: var(--darker);
}

.step h4 {
  font-size: 1.25em;
  font-weight: 300;
  color: var(--grey);
  margin: 0;
}

.step hr {
  margin: 0.5em 0;
  border: 1px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.step p {
  font-size: 1em;
  font-weight: 300;
  color: var(--black);
  line-height: 1.5;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .step {
    width: 75%;
    height: fit-content;
  }

  #roadmap h3 {
    width: 90vw;
    font-size: 1.75em;
    text-wrap: wrap;
  }

  #steps {
    width: 90vw;
  }
}
