:root {
  --main-green: #004d14;
  --dark-green: #005a31;
  --medium-green: #00703d;
  --light-green: #66bb6a;
  --pastel-green: #a5d6a7;
  --white: #f6f7f2;
  --orange: #ff6b18;
  --orange2: #ff8c01;
  --grey: #272727;
  --black: #000;
  --lighter-grey: #4d4d4d;
  --darker: #e1e5dc;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
  line-height: 1.5;
}

#footer {
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  margin: 2em 0;
  margin-bottom: 0;
  height: fit-content;
  justify-content: center;
  background-color: var(--grey);
  color: white;
  padding: 1em 0;
}

.contact-form {
  width: fit-content;
  margin: 1em;
  position: relative;
  border-radius: 3px;
  height: 27.5em;
  padding: 0.5em;
}

.contact-form h3 {
  font-size: 1.25em;
  font-weight: 300;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
}

.contact-form p {
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  margin-left: 0.5em;
  text-align: justify;
}

#action-btns {
  position: absolute;
  right: 2.2em;
  bottom: -4em;
}

#action-btns button {
  padding: 0.3em 0.8em;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  line-height: 1.5;
  align-items: center;
  font-size: 1em;
  font-weight: 300;
  margin: 1em 0.5em;
  width: fit-content;
  height: fit-content;
  text-wrap: nowrap;
}

#action-btns #submit {
  background-color: var(--medium-green);
  color: white;
  border: none;
}

#action-btns #cancel {
  background-color: var(--orange);
  color: white;
  border: none;
}

#action-btns #submit:hover {
  background-color: var(--dark-green);
  transform: scale(1.025);
}

#action-btns #cancel:hover {
  background-color: var(--orange2);
  transform: scale(1.025);
}

.contact-form form {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  position: relative;
  border-radius: 3px;
  padding: 0.5em;
  font-size: 1em;
  font-weight: 300;
}

#service-select {
  border-radius: 3px;
  background: none;
  border: 1px solid var(--pastel-green);
  color: white;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 300;
  padding: 0.25em 1em;
  margin: 0 1em;
  width: 18em;
}

.fields {
  display: flex;
  align-items: center;
  background-color: none;
  border-radius: 3px;
  margin: 0.2em;
  padding-right: 1em;
  cursor: pointer;
}

.fields input {
  background: none;
  border: 1px solid var(--pastel-green);
  border-radius: 3px;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 300;
  padding: 0.25em 1em;
  margin: 0 1em;
  width: 16em;
}

.fields input:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
}

.fields label {
  width: 25%;
}

.fields select option {
  color: var(--grey);
}

#message {
  font-size: 1em;
  font-weight: 300;
  background: none;
  border: 1px solid var(--pastel-green);
  border-radius: 3px;
  margin: 0 1em;
  color: var(--white);
  line-height: normal;
  padding: 0.5em;
  width: 17.5em;
}

.contact-details {
  width: fit-content;
  max-width: 30em;
  margin: 1em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  font-size: 1em;
  font-weight: 300;
}

.contact-details p {
  text-align: justify;
}

.contact-details p:hover {
  color: var(--white);
}

.address {
  margin: 0.25em;
  display: flex;
  align-items: center;
}

.address img {
  width: 1.25em;
  margin: 0.25em 0.75em;
  border-right: 1px solid var(--pastel-green);
  border-bottom: 1px solid var(--pastel-green);
  border-radius: 3px;
  padding: 0.25em;
}

.address a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 800px) {
  #footer {
    flex-wrap: wrap;
  }

  .contact-form {
    height: max-content;
  }

  .fields {
    flex-direction: column;
    align-items: flex-start;
    margin: 0.5em;
  }

  .fields label {
    width: 90%;
    margin: 0.25em;
  }

  .contact-details {
    width: 90vw;
    align-items: center;
  }

  .contact-details p {
    width: 75%;
  }
}
