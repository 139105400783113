:root {
  --main-green: #004d14;
  --dark-green: #005a31;
  --medium-green: #00703d;
  --light-green: #66bb6a;
  --pastel-green: #a5d6a7;
  --white: #f6f7f2;
  --orange: #ff6b18;
  --orange2: #ff8c01;
  --grey: #272727;
  --black: #000;
  --lighter-grey: #4d4d4d;
  --darker: #e1e5dc;
}

#whole {
  padding-top: 1em;
  width: 100vw;
  background-color: white;
  border-radius: 10px;
}

#whole h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  text-align: center;
}

#partners {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em;
}

#partners a {
  height: fit-content;
  width: fit-content;
  background: var(--white);
  border-radius: 7px;
  margin: 0.25em;
  padding: 0.5em 1em;
  display: flex;
  align-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--white);
}

#partners a:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

#partners a img {
  height: 1.5em;
  width: auto;
}

#sunpro {
  width: 100vw;
  height: fit-content;
  display: flex;
  justify-content: center;
}

#sunpro a {
  color: var(--black);
  text-decoration: none;
  text-wrap: wrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
  font-size: 1em;
  font-weight: 300;
  display: flex;
  align-items: center;
  height: 3em;
  width: fit-content;
  background: var(--white);
  border-radius: 10px;
  margin: 0.5em;
  padding: 0 1em 0 0.5em;
  transition: all 0.3s ease-in-out;
  position: relative;
}

#sunpro a:hover {
  background-color: var(--dark-green);
  color: white;
}

#sunpro a img {
  margin-right: 0.8em;
  height: 2em;
}
