:root {
  --main-green: #004d14;
  --dark-green: #005a31;
  --medium-green: #00703d;
  --light-green: #66bb6a;
  --pastel-green: #a5d6a7;
  --white: #f6f7f2;
  --orange: #ff6b18;
  --orange2: #ff8c01;
  --grey: #272727;
  --black: #000;
  --lighter-grey: #4d4d4d;
  --darker: #e1e5dc;
}

#env {
  background-image: url("../intro.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 30em;
  width: 100vw;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-top: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    sans-serif;
}

#cont {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 90, 49, 0.75) 60%,
    rgba(255, 107, 24, 0.75) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 5px;
}

#take {
  width: 50%;
}

#take h3 {
  font-size: 2.5em;
  font-weight: 300;
  color: white;
  text-align: left;
  margin: 0;
  margin-left: 1.5em;
}

#take p {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.5;
  color: white;
  text-align: left;
  margin-left: 2.5em;
}

@media screen and (max-width: 800px) {
  #env {
    height: 30em;
  }

  #cont {
    height: 100%;
    align-items: center;
  }

  #take {
    width: 75%;
    height: fit-content;
    padding: 0;
  }

  #take h3 {
    font-size: 1.75em;
    text-align: center;
    margin: 0;
  }

  #take p {
    font-size: 1.25em;
    text-align: center;
    margin: 1em 0;
  }
}
